<!-- *************************** 产品购买 ****************************** -->
<template>
  <div class="container">
    <div class="vip">
      <div class="container">
        <div class="content">
          <h4 class="title">VIP高速套餐</h4>
          <div class="vip_top">
            <p>
              Transgle网路加速神器直接连接路由器，底层网络直连，无需下载APP，再也不必担心隐私安全问题。SDWAN技术提供流畅用户体验，再也无需国内国外来回切换，让你彻底摆脱VPN。
            </p>
            <p>
              Transgle为各位游戏玩家提供游戏专线，独立优化IP池，身在海外玩国服游戏再也不卡机、不延迟、不掉线，完美支持加速王者荣耀、和平精英、英雄联盟
              、魔兽世界等国服游戏。在玩游戏的同时还可支持YY语音、听音乐、看视频。
            </p>
            <div class="vip_box">
              <div class="box">
                <img src="../assets/home_ic_line.png" alt />
                <span>精品路线</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_hd.png" alt />
                <span>高清播放</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_service.png" alt />
                <span>客服优先</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_discounts.png" alt />
                <span>物超所值</span>
              </div>
            </div>
          </div>
          <!--*******  套餐选择  ******** -->
          <div class="vip_bottom">
            <div class="vip_b_box_content" v-if="productLists.length > 0">
              <div
                v-for="(item, index) in productLists"
                :key="item.order_id"
                @click="choseMeals(index)"
                :class="[{ meals_active: mealsIndex == index }, 'vip_b_box']"
                :style="{ marginRight: (index + 1) % 4 == 0 ? 0 : '101px' }"
              >
                <h4>{{ item.title }}</h4>
                <p class="price price_red" v-if="item.id == 13">
                  {{ item.price / 100 }}
                  <span class="currency">{{ item.currency }}</span>
                  <!--                  <span class="days">/{{item.days}}天</span>-->
                  <!--                  <span class="year">(年付)</span>-->
                </p>
                <p class="price" v-else-if="item.id == 12">
                  {{ item.price / 100 }}
                  <span class="currency">{{ item.currency }}</span>
                  <!-- <span class="line_through_price"> 原价 349AUD</span> -->
                  <!--                  <span class="days">/{{item.days}}天</span>-->
                  <!--                  <span class="year">(年付)</span>-->
                </p>
                <p class="price" v-else>
                  {{ item.price / 100 }}
                  <span class="currency">{{ item.currency }}</span>
                  <!--                  <span class="days">/{{item.days}}天</span>-->
                  <!--                  <span class="year">(年付)</span>-->
                </p>
                <p>
                  <!--                  <span>类型</span>-->
                  {{ item.type_text }}
                </p>
                <p>
                  <span>区域</span>
                  {{ item.region.region }}
                </p>
                <p>
                  <span>{{
                    item.device_limit === 0
                      ? "同时上线多台设备"
                      : "同时上线" + item.device_limit + "台设备"
                  }}</span>
                </p>
                <p class="device">{{ item.description.split("|")[0] }}</p>
                <p class="device">{{ item.description.split("|")[1] }}</p>
                <span v-if="item.is_recommend == 1" class="intro_logo"
                  >推荐</span
                >
              </div>
            </div>
          </div>
          <!--*******  天数选择  ******** -->
          <div class="vip_bottom" v-if="choseId == 13">
            <div class="vip_b_box_content" v-if="dayList.length > 0">
              <div
                v-for="(item, index) in dayList"
                :key="index"
                @click="choseDays(index)"
                :class="[
                  { meals_active: dayIndex == index },
                  'vip_b_box',
                  'vip_day_box',
                ]"
                :style="{ marginRight: (index + 1) % 4 == 0 ? 0 : '101px' }"
              >
                <p class="chose_day">
                  <span class="chose_day_mark">设备押金</span>
                </p>

                <p class="cost_price">
                  <span class="cost_price">30天到期后退还</span>
                </p>
                <p class="real_price">${{ item.real_price / 100 }}</p>
              </div>
            </div>
          </div>
          <div class="vip_bottom" v-else>
            <div class="vip_b_box_content" v-if="dayList.length > 0">
              <div
                v-for="(item, index) in dayList"
                :key="index"
                @click="choseDays(index)"
                :class="[
                  { meals_active: dayIndex == index },
                  'vip_b_box',
                  'vip_day_box',
                ]"
                :style="{ marginRight: (index + 1) % 4 == 0 ? 0 : '101px' }"
              >
                <p class="chose_day">
                  {{ item.days }}
                  <span class="chose_day_mark">天</span>
                </p>
                <p class="cost_price">
                  <span
                    class="cost_price"
                    v-show="item.cost_price !== item.real_price"
                    >{{ item.discounts_off }}</span
                  >
                </p>
                <p class="real_price">${{ item.real_price / 100 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--*******  支付方式  ******** -->
    <div>
      <!--      <div class="pay_notice">-->
      <!--        <em></em>* 首次购买设备或含设备的套餐时，需额外支付168.99AUD的设备采购费及15AUD的邮费。-->
      <!--      </div>-->
      <div class="pay_way"><em></em>选择支付方式</div>
      <div class="pay">
        <div class="pay_type" v-if="payWays">
          <Button
            :class="[{ pay_active: payIndex == index }, 'item']"
            v-for="(item, index) in payWays"
            :key="item.key"
            @click="payChose(index)"
          >
            <div class="img">
              <img v-if="index == 0" src="../assets/royalpay.png" alt />
              <!-- <img v-if="index == 1" src="../assets/paylinx.svg" alt /> -->
              <img v-if="index == 1" src="../assets/paypal2.png" height="30px" width="120px" alt />
              <img
                v-if="index == 2"
                class="paypal"
                src="../assets/transfer2.png"
                alt
              />
              <img
                v-if="index == 3"
                class="paypal"
                src="../assets/wallet.png"
                alt
              />
            </div>
            <span>{{ item.title }}</span>
          </Button>
        </div>
        <div class="transfer" v-if="payIndex==2">
          <p>Account Name: Mesup Pty Ltd</p>
          <p>BSB: 063019</p>
          <p>Account No.: 12180023</p>
          <p>Ref: Phone number & Name</p>
        </div>
        <div class="royal_pay" v-if="payIndex < 1">
          <el-radio-group v-model="channel">
            <el-radio label="Wechat" border>微信支付</el-radio>
            <el-radio label="Alipay" border>支付宝支付</el-radio>
          </el-radio-group>
        </div>
        <div class="rate" v-if="channel == 'Wechat' && payIndex < 1">
          微信汇率: {{ wechat_rate }}
        </div>
        <div class="rate" v-if="channel == 'Alipay' && payIndex < 1">
          支付宝汇率: {{ alipay_rate }}
        </div>
        <div class="info" v-if="productLists.length > 0">
          {{ productLists[mealsIndex].title }}
          <span class="day">{{ dayList[dayIndex].days }}</span
          >天
        </div>
        <div class="info" v-if="productLists.length > 0">
          账户使用费: {{ dayList[dayIndex].real_price / 100 }}
          {{ dayList[dayIndex].currency }}
        </div>
        <!--        <div-->
        <!--            class="info"-->
        <!--            v-if="productLists.length>0"-->
        <!--        >-->
        <!--          <div v-if="productLists[mealsIndex].type === 2 || productLists[mealsIndex].type === 3 || productLists[mealsIndex].type === 6">-->
        <!--            * 设备一次性购买: 168.99 {{dayList[dayIndex].currency}}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div-->
        <!--            class="info"-->
        <!--            v-if="productLists.length>0"-->
        <!--        >-->
        <!--          <div v-if="productLists[mealsIndex].type === 2 || productLists[mealsIndex].type === 3 || productLists[mealsIndex].type === 6">-->
        <!--            * 邮费: 15 {{dayList[dayIndex].currency}}-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="bug_now" v-if="payIndex!=2">
          <button @click="submit">立即购买</button>
        </div>
        <div class="bug_now" v-if="payIndex==2">
          <p style="color:#ff6854">* 支付成功后请联系客服确认</p>
        </div>
        <!--*****************************  二维码弹框   **********************************-->
        <div class="mask" v-if="isShow">
          <div class="qr_box">
            <div class="qr_inner_box">
              <h1>{{ channel == "Wechat" ? "微信支付" : "支付宝支付" }}</h1>
              <div id="qrcode"></div>
              <div class="close">
                <i
                  @click="closeMask"
                  style="color: #33333378; font-size: 20px"
                  class="el-icon-close"
                ></i>
              </div>
            </div>
          </div>
          <!--*******  付款成功后提示弹框   ******** -->
          <div class="qr_box" v-if="status">
            <div class="inner_box">
              <i
                style="font-size: 50px"
                :class="{
                  'el-icon-circle-check': status == 1,
                  'el-icon-circle-close': status == 2 || status == 3,
                }"
              ></i>
              <p style="font-size: 20px; margin-top: 20px">
                {{ statusStr[status] }}
              </p>
              <div class="btn_bottom" v-if="status == 1">
                <router-link to="/admin_center/myorder">
                  <button>查看订单</button>
                </router-link>
                <button @click="closeMask" style="margin-left: 80px">
                  继续逛逛
                </button>
              </div>
              <div class="close">
                <i
                  @click="closeMask"
                  style="color: #33333378; font-size: 20px"
                  class="el-icon-close"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <!--*****************************  二维码弹框   **********************************-->
      </div>
    </div>
  </div>
</template>
<script>
import {
  getOrderStatusApi,
  getProductListApi,
  getProductRateApi,
  submitOrderApi,
} from "api/Recharge";
import QRcode from "qrcodejs2";

export default {
  data() {
    return {
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      productLists: [],
      payWays: [
        { key: "RoyalPay", title: "" },
        // { key: "Paylinx", title: "" },
        { key: "Paypal", title: "" },
        { key: "Transfer", title: "银行转账" },
        { key: "Credit", title: "我的钱包" },
      ],
      mealsIndex: 0, //当前选择的套餐序列
      dayIndex: 0, //当前选择的天数
      payIndex: 0, //当前选择的支付序列  0：royalpay,1:钱包
      channel: "Wechat", //royalpay默认选择微信支付
      rate: {
        RoyalPay: {
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        },
        Paylinx: {
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        },
      },
      alipay_rate: "", //支付宝汇率
      wechat_rate: "", //微信汇率
      isShow: false, //支付二维码弹窗
      status: "", //订单状态
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单超时未支付",
      },
      timer: null,
      choseId: 0,
      dayList: [],
    };
  },
  methods: {
    //点击选择套餐
    choseMeals(index) {
      this.mealsIndex = index;
      this.choseId = this.productLists[index].id;
      this.dayList = this.productLists[index].product_price;
      this.dayIndex = 0;
    },
    //点击选择天数
    choseDays(index) {
      this.dayIndex = index;
    },
    //选择支付方式 ---->  钱包/royalpay
    payChose(index) {
      this.payIndex = index;
      this.changeRate(index);
    },

    changeRate(index) {
      if (this.payWays[index].key == "RoyalPay") {
        this.wechat_rate = this.rate.RoyalPay.wechat_rate;
        this.alipay_rate = this.rate.RoyalPay.alipay_rate;
      }
      if (this.payWays[index].key == "Paylinx") {
        this.wechat_rate = this.rate.Paylinx.wechat_rate;
        this.alipay_rate = this.rate.Paylinx.alipay_rate;
      }
    },
    //提交订单
    submit() {
      const loading = this.$loading({
        lock: true,
        text: "提交订单中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {
        payment_type: this.payWays[this.payIndex].key,
        product_id: this.productLists[this.mealsIndex].id,
        channel: this.channel,
        days: this.dayList[this.dayIndex].days,
      };
      submitOrderApi(params)
        .then((res) => {
          if (res.code == 0) {
            if (params.payment_type == "Paypal") {
              window.open(res.data.code_url, "_blank");
            } else {
              this.isShow = true;
              // document.getElementById('qrcode').innerHTML="";
              setTimeout(() => {
                let shareUrl = res.data.code_url;
                let qrcode = new QRcode("qrcode", {
                  text: shareUrl,
                  width: 200, //图像宽度
                  height: 200, //图像高度
                  colorDark: "#517cfc",
                  colorLight: "#ffffff",
                  correctLevel: QRcode.CorrectLevel.H,
                });
                qrcode.clear(); //清除二维码
                qrcode.makeCode(shareUrl); //生成另一个新的二维码
                this.timer = setInterval(
                  () => this.getResult(res.data.order_id),
                  1000
                );
              }, 500);
            }
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then((res) => {
          if (res.code == 0) {
            const { status } = res.data;
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
      this.status = 0;
      clearInterval(this.timer);
    },
  },
  mounted() {
    //获取套餐列表
    getProductListApi().then((res) => {
      if (res.code == 0) {
        this.productLists = res.data;
        const index = this.productLists.findIndex(
          (item) => item.is_recommend == 1 //找出推荐
        );
        this.mealsIndex = index;
        this.choseId = res.data[index].id;
        this.dayList = res.data[index].product_price;
      }
    });
    //获取汇率
    getProductRateApi({ payment_type: "RoyalPay" }).then((res) => {
      if (res.code == 0) {
        if (res.data.RoyalPay != undefined) {
          this.rate.RoyalPay.wechat_rate = res.data.RoyalPay.wechat_rate;
          this.rate.RoyalPay.alipay_rate = res.data.RoyalPay.alipay_rate;
        }

        if (res.data.Paylinx != undefined) {
          this.rate.Paylinx.wechat_rate = res.data.Paylinx.wechat_rate;
          this.rate.Paylinx.alipay_rate = res.data.Paylinx.alipay_rate;
        }

        this.changeRate(0);
      }
    });
  },
};
</script>
<style lang='scss'>
.vip {
  padding: 32px 0;
  .title {
    font-size: 48px;
    font-weight: 400;
    color: #202020;
    line-height: 67px;
  }
  .vip_top {
    padding: 0 70px;
    .title {
      font-size: 48px;
      font-weight: 400;
      color: #202020;
      line-height: 67px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 28px;
      margin: 32px 0;
      text-align: justify;
    }
    .vip_box {
      @include flex_between;
      .box {
        width: 82px;
        img {
          width: 100%;
          padding-bottom: 20px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #202020;
          line-height: 20px;
        }
      }
    }
  }
  .vip_bottom {
    .vip_b_box_content {
      display: flex;
      flex-wrap: wrap;
      .meals_active {
        h4 {
          font-size: 18px;
        }
        border: 2px solid #517cfc;
      }

      .vip_b_box {
        flex-shrink: 0;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 45px;
        box-sizing: border-box;
        margin-right: 101px;
        width: 220px;
        padding: 41px 20px 25px 20px;
        background: #edf9ff;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        &:hover {
          box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.1);
        }
        .intro_logo {
          display: inline-block;
          padding: 3px 20px;
          color: #fff;
          font-size: 18px;
          position: absolute;
          right: 0;
          top: 0;
          border-bottom-left-radius: 5px;
          background: #517cfc;
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: #202020;
          line-height: 25px;
        }
        .price {
          font-size: 26px;
          span {
            font-size: 14px;
          }
          .currency {
            color: #517cfc;
          }
          .days {
            color: #4a4a4a;
          }
          .year {
            font-size: 8px;
          }
        }
        .price_red {
          font-size: 26px;
          color: #ff6854;
          span {
            font-size: 14px;
          }
          .currency {
            color: #ff6854;
          }
          .days {
            color: #4a4a4a;
          }
          .year {
            font-size: 8px;
          }
        }
        .device {
          color: #5a5a5a;
          span {
            color: #517cfc;
          }
        }
        p {
          font-size: 14px;
          font-weight: bold;
          color: #517cfc;
          line-height: 42px;
          span {
            font-size: 14px;
            font-weight: bold;
            color: #5a5a5a;
          }
        }
        button {
          width: 98px;
          height: 38px;
          border-radius: 5px;
          border: 1px solid #517cfc;
          font-size: 14px;
          font-weight: 500;
          color: #517cfc;
          line-height: 20px;
          background: #edf9ff;
        }
        .chose_day {
          font-size: 36px;
          color: #517cfc;
          .chose_day_mark {
            font-size: 24px;
          }
        }
        .cost_price {
          padding: 5px 0;
          height: 30px;
          box-sizing: border-box;
          span {
            font-size: 16px;
            color: #ff6854;
            //text-decoration: line-through;
          }
        }
        .real_price {
          font-size: 18px;
          color: #202020;
        }
      }
      .line_through_price {
        font-size: 18px !important;
        color: #517cfc !important;
        text-decoration: line-through;
        text-decoration-color: #ff6854;
        display: block;
      }
      .vip_day_box {
        margin-top: 30px;
        background: #fff;
        box-shadow: 0px 10px 26px 0px rgba(134, 134, 162, 0.11);
        p {
          line-height: 20px;
        }
      }
    }
  }
}
.pay_notice {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #8c939d;
  line-height: 30px;
  margin-bottom: 30px;
  vertical-align: middle;
}
.pay_way {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 22px;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 30px;
  margin-bottom: 30px;
  vertical-align: middle;
  em {
    display: inline-block;
    width: 4px;
    height: 10px;
    background: #517cfc;
    margin-right: 10px;
  }
}
.pay {
  text-align: left;
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 100px;
  .pay_type {
    display: flex;
    .item:first-child {
      img {
        width: 120px;
      }
    }
    .item {
      margin-right: 20px;
      height: 55px;
      width: 140px;
      padding: 10px;
      cursor: pointer;
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      background: #fff;
      border: 1px solid #dcdfe6;
      &:hover {
        span {
          color: #517cfc;
        }
      }
      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #4c5a68;
        line-height: 20px;
      }
      .paypal {
        width: 30px;
      }
    }
    .pay_active {
      background: #e6a23c29;
      color: #517cfc;
    }
  }
  .el-radio.is-bordered {
    display: block;
    margin-bottom: 15px;
  }
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0;
  }
  ul {
    li {
      color: #7e7e7e;
      font-size: 14px;
      margin-bottom: 10px;
      .li_item {
        @include flex_between;
        p {
          .day {
            color: #000;
          }
          .account {
            color: #6dd400;
          }
        }
      }
    }
  }
  .all_pay {
    font-size: 18px;
    color: #202020;
    margin-top: 20px;
    @include flex_between;
  }
  .bug_now {
    text-align: left;
    margin-top: 20px;
    button {
      width: 128px;
      height: 40px;
      background: #517cfc;
      border-radius: 4px;
      color: #fff;
    }
  }
  .info {
    margin: 15px 0;
  }
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  .qr_box {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 430px;
    height: 430px;
    border-radius: 5px;
    .qr_inner_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 28px;
        margin: 47px;
      }
    }
    .inner_box {
      position: relative;
      padding: 120px 0;
      .el-icon-circle-check {
        color: green;
      }
      .btn_bottom {
        margin-top: 80px;
        button {
          width: 120px;
          height: 40px;
          line-height: 40px;
          color: #fff;
          background: #517cfc;
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.transfer{
    p {
      font-size: 16px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 28px;
      text-align: justify;
    }
  }
</style>